import React, { useState, useRef, useCallback } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import HeaderComponent from '../../components/Header';
import Toast from '../../components/Toast';
import Modal from '../../components/Modal';
import { fetchAuthSession } from '@aws-amplify/auth';
import {
  PageContainer,
  ContentContainer,
  LighterArea,
  Title,
  UploadArea,
  UploadText,
  FileList,
  FileItem,
  FileInfo,
  FileName,
  FileUrl,
  ButtonGroup,
  Button,
  DeleteButton,
  Footer
} from './styles';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB in bytes

interface FileType {
  id: number;
  file_name: string;
  url: string;
}

const UserFilesPage: React.FC = () => {
  const [files, setFiles] = useState<FileType[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const urlRefs = useRef<(HTMLInputElement | null)[]>([]);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setIsUploading(true);
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('file', file);

    try {
      const { tokens } = await fetchAuthSession();
      const token = tokens?.idToken?.toString();

      if (!token) {
        throw new Error('No token available');
      }

      console.log('Token retrieved:', token.substring(0, 10) + '...'); // Log part of the token for debugging

      const response = await fetch('http://localhost:5000/api/upload', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Upload response:', data);

      // Handle successful upload
      setFiles(prevFiles => [...prevFiles, { 
        id: data.id, 
        file_name: file.name, 
        url: data.filePath 
      }]);
      setToastMessage('File uploaded successfully');
      setShowToast(true);
    } catch (error) {
      console.error('Error uploading file:', error);
      setModalMessage('Error uploading file. Please try again.');
      setShowModal(true);
    } finally {
      setIsUploading(false);
    }
  }, [setFiles, setToastMessage, setShowToast, setModalMessage, setShowModal]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop: onDrop as unknown as DropzoneOptions['onDrop'],
    maxSize: MAX_FILE_SIZE
  });

  const handleCopyUrl = useCallback((index: number) => {
    const urlInput = urlRefs.current[index];
    if (urlInput) {
      urlInput.select();
      document.execCommand('copy');
      setToastMessage('URL copied to clipboard!');
      setShowToast(true);
    }
  }, []);

  const handleDelete = useCallback((id: number) => {
    setFiles(prevFiles => prevFiles.filter(file => file.id !== id));
  }, []);

  const MemoizedFileItem = React.memo(({ file, index }: { file: FileType; index: number }) => (
    <FileItem key={file.id}>
      <FileInfo>
        <FileName>{file.file_name}</FileName>
        <FileUrl
          ref={el => urlRefs.current[index] = el}
          value={file.url}
          readOnly
          onClick={() => handleCopyUrl(index)}
        />
      </FileInfo>
      <ButtonGroup>
        <Button onClick={() => handleCopyUrl(index)}>Copy URL</Button>
        <DeleteButton onClick={() => handleDelete(file.id)}>Delete</DeleteButton>
      </ButtonGroup>
    </FileItem>
  ));

  return (
    <PageContainer>
      <HeaderComponent />
      <ContentContainer>
        <LighterArea>
          <Title>Your Files</Title>
          <UploadArea {...getRootProps()} style={{ pointerEvents: isUploading ? 'none' : 'auto' }}>
            <input {...getInputProps()} disabled={isUploading} />
            {
              isDragActive ?
                <UploadText>Drop the files here ...</UploadText> :
                isUploading ?
                  <UploadText>Uploading...</UploadText> :
                  <UploadText>Click or drag files here to upload (Max 10 MB)</UploadText>
            }
          </UploadArea>
          <FileList>
            {files.map((file, index) => (
              <MemoizedFileItem key={file.id} file={file} index={index} />
            ))}
          </FileList>
        </LighterArea>
      </ContentContainer>
      <Footer>© 2024 Malorian Software</Footer>
      <Toast 
        message={toastMessage} 
        show={showToast} 
        onClose={() => setShowToast(false)} 
      />
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="File Error"
        message={modalMessage}
      />
    </PageContainer>
  );
};

export default UserFilesPage;
