import React, { useState } from 'react';
import { confirmResetPassword } from 'aws-amplify/auth';
import InputField from '../../components/InputField';
import Button from '../../components/Button';

const ConfirmPasswordReset: React.FC = () => {
  const [username, setUsername] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await confirmResetPassword({ username, confirmationCode, newPassword });
      setMessage('Password reset successfully. You can now log in with your new password.');
    } catch (error) {
      console.error('Error confirming reset password:', error);
      setMessage('Error confirming reset password. Please try again.');
    }
  };

  return (
    <div>
      <h2>Confirm Password Reset</h2>
      <form onSubmit={handleSubmit}>
        <InputField
          label="Username"
          type="text"
          name="username"
          id="username"
          required
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <InputField
          label="Confirmation Code"
          type="text"
          name="confirmationCode"
          id="confirmationCode"
          required
          value={confirmationCode}
          onChange={(e) => setConfirmationCode(e.target.value)}
        />
        <InputField
          label="New Password"
          type="password"
          name="newPassword"
          id="newPassword"
          required
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <Button type="submit">Reset Password</Button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ConfirmPasswordReset;