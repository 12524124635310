import React from 'react';
import { styled } from 'styled-components';

interface InputFieldProps {
  label: string;
  type: string;
  name: string;
  id: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

const StyledLabel = styled.label`
  display: block;
  color: #ccc;
  margin-bottom: 4px;
`;

const StyledInput = styled.input`
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
  border-radius: 4px;
  padding: 8px;
  margin-top: 8px;
  width: 100%;

  &:focus {
    outline: none;
    border-color: #777;
  }
`;

const StyledFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  width: 100%;
`;

const InputField: React.FC<InputFieldProps> = ({ label, type, name, id, value, onChange, required = false }) => {
  return (
    <StyledFieldContainer>
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
      <StyledInput type={type} id={id} name={name} value={value} onChange={onChange} required={required} />
    </StyledFieldContainer>
  );
};

export default InputField;