import React, { useState } from 'react';
import { createUser } from '../../services/userService';
import HeaderComponent from '../../components/Header';
import Toast from '../../components/Toast';
import {
  PageContainer,
  ContentContainer,
  LighterArea,
  Title,
  StyledForm,
  Input,
  Select,
  Button,
  Footer
} from './styles';

const UserCreationPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('user');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await createUser({ username, email, password, role });
      setToastMessage('User created successfully');
      setShowToast(true);
      setUsername('');
      setEmail('');
      setPassword('');
      setRole('user');
    } catch (error) {
      console.error('Error creating user:', error);
      setToastMessage('Failed to create user');
      setShowToast(true);
    }
  };

  return (
    <PageContainer>
      <HeaderComponent />
      <ContentContainer>
        <LighterArea>
          <Title>Create New User</Title>
          <StyledForm onSubmit={handleSubmit}>
            <Input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              required
            />
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            <Select value={role} onChange={(e) => setRole(e.target.value)}>
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </Select>
            <Button type="submit">Create User</Button>
          </StyledForm>
        </LighterArea>
      </ContentContainer>
      <Footer>© 2024 Malorian Software</Footer>
      <Toast 
        message={toastMessage} 
        show={showToast} 
        onClose={() => setShowToast(false)} 
      />
    </PageContainer>
  );
};

export default UserCreationPage;