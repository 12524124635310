import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import MainPage from './views/MainPage/MainPage';
import LoginPage from './views/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute';
import PasswordResetRequest from './views/PasswordReset/PasswordResetRequest';
import ConfirmPasswordReset from './views/PasswordReset/ConfirmPasswordReset';
import GlobalStyle from './styles/GlobalStyle';
import UserFilesPage from './views/UserFilesPage/UserFilesPage';
import UserCreationPage from './views/UserCreationPage/UserCreationPage';
import RandomImage from './components/RandomImage';
import RandomImageUrl from './components/RandomImageUrl';
import RandomImageProxy from './components/RandomImageProxy';

const App: React.FC = () => {
  return (
    <Router>
      <GlobalStyle />
      <div className="App">
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/reset-password" element={<PasswordResetRequest />} />
          <Route path="/confirm-reset-password" element={<ConfirmPasswordReset />} />
          <Route path="/random-image" element={<RandomImage />} />
          <Route path="/random-image-url" element={<RandomImageUrl />} />
          <Route path="/random-image-proxy" element={<RandomImageProxy />} />

          {/* Protected routes */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <MainPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/files"
            element={
              <PrivateRoute>
                <UserFilesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/create-user"
            element={
              <PrivateRoute adminOnly>
                <UserCreationPage />
              </PrivateRoute>
            }
          />

          {/* Redirect any unknown route to /login */}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
