import React, { useEffect, useState } from 'react';
import { getRandomImageUrl } from '../services/imageService';

const RandomImage: React.FC = () => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchImageUrl = async () => {
      try {
        const url = await getRandomImageUrl();
        setImageUrl(url);
      } catch (error) {
        console.error('Error fetching random image:', error);
        setError('Failed to fetch random image. Please try again later.');
      }
    };

    fetchImageUrl();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (!imageUrl) {
    return <div>Loading...</div>;
  }

  return <img src={imageUrl} alt="Random" />;
};

export default RandomImage;
