import styled from 'styled-components';
import { Button as BaseButton } from '../UserCreationPage/styles';  // Reusing the Button style

export const PageContainer = styled.div`
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const LighterArea = styled.div`
  background-color: #1a1a1a;
  border-radius: 8px;
  padding: 40px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h1`
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 20px;
`;

export const UploadArea = styled.div`
  border: 2px dashed #333333;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #222222;
  }
`;

export const UploadText = styled.p`
  color: #ffffff;
  font-size: 16px;
`;

export const FileList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
`;

export const FileItem = styled.li`
  color: #ffffff;
  padding: 10px;
  background-color: #222222;
  margin-bottom: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FileName = styled.span`
  margin-bottom: 5px;
`;

export const FileUrl = styled.input`
  background-color: #333333;
  border: none;
  color: #aaaaaa;
  padding: 5px;
  border-radius: 3px;
  width: 300px;
  font-size: 12px;
  cursor: pointer;

  &:focus {
    outline: none;
    background-color: #444444;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

export const Button = styled(BaseButton)`
  padding: 5px 10px;
`;

export const DeleteButton = styled(Button)`
  background-color: #ff4444;

  &:hover {
    background-color: #cc0000;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  padding: 20px 0;
  color: #ffffff;
  opacity: 60%;
  text-align: center;
`;