import api from './api';

export const getRandomImageUrl = async (): Promise<string> => {
  try {
    const response = await api.get('/randomImage', {
      maxRedirects: 0,
      validateStatus: (status) => status === 302,
    });
    
    if (response.headers && response.headers.location) {
      return response.headers.location;
    } else {
      throw new Error('No redirect URL found in response');
    }
  } catch (error) {
    console.error('Error fetching random image URL:', error);
    throw error;
  }
};
