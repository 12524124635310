import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn, fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import InputField from '../../components/InputField';
import Button from '../../components/Button';
import { StyledTitle } from './styles';
import { styled } from 'styled-components';
import { fadeIn } from '../../styles/animations';
import { useAuth } from '../../context/AuthProvider';
import { getUserRole } from '../../services/userService';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #202123;
`;

const BoxContainer = styled.div`
  padding: 30px 80px;
  background-color: #171819;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 1.5s ease-out;
`;

const Quote = styled.p`
  color: #ACA59A;
  margin: 16px 0px;
`;

const Footer = styled.footer`
  width: 100%;
  padding: 20px 0;
  color: #5f5f5f;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
`;

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const { refreshUser } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  const checkCurrentUser = useCallback(async () => {
    try {
      const currentUser = await getCurrentUser();
      if (currentUser) {
        // User is already signed in, redirect to main page
        navigate('/');
      }
    } catch (error) {
      // No current user, stay on login page
      console.log('No current user');
    }
  }, [navigate]);

  useEffect(() => {
    checkCurrentUser();
  }, [checkCurrentUser]);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const { isSignedIn } = await signIn({ username, password });
      if (isSignedIn) {
        await handleSuccessfulLogin();
      }
    } catch (error) {
      console.error('Error during sign in:', error);
      setError('Invalid username or password');
    }
  };

  const handleSuccessfulLogin = async () => {
    const session = await fetchAuthSession();
    const token = session.tokens?.idToken?.toString();
    if (token) {
      localStorage.setItem('token', token);
      
      try {
        const currentUser = await getCurrentUser();
        if (currentUser && currentUser.username) {
          // Fetch user role from the database
          const role = await getUserRole(currentUser.username);
          
          // Refresh user context with the fetched role
          await refreshUser(role);
          
          navigate('/');
        } else {
          throw new Error('Unable to get current user information');
        }
      } catch (error) {
        console.error('Error fetching user role:', error);
        setError('Failed to fetch user role. Please try again.');
      }
    }
  };

  return (
    <Container>
      <BoxContainer>
        <StyledTitle>Login</StyledTitle>
        <Quote>The face under the mask... Is that... your true face?</Quote>
        <form onSubmit={handleLogin}>
          <InputField
            label="Username"
            type="text"
            name="username"
            id="username"
            required
            value={username}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
          />
          <InputField
            label="Password"
            type="password"
            name="password"
            id="password"
            required
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          />
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <Button type="submit" animate="fadeIn" animationDuration={1000}>Login</Button>
        </form>
      </BoxContainer>
      <Footer>© 2024 Malorian Software</Footer>
    </Container>
  );
};

export default LoginPage;
