import React, { useEffect } from 'react';
import { getRandomImageUrl } from '../services/imageService';

const RandomImageProxy: React.FC = () => {
  useEffect(() => {
    const fetchAndRedirect = async () => {
      try {
        const imageUrl = await getRandomImageUrl();
        window.location.href = imageUrl;
      } catch (error) {
        console.error('Error fetching random image URL:', error);
        // You might want to display an error message here
      }
    };

    fetchAndRedirect();
  }, []);

  return <div>Redirecting to random image...</div>;
};

export default RandomImageProxy;
