import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';
import { getUserRole } from '../services/userService'; // Update this import

interface PrivateRouteProps {
  children: JSX.Element;
  adminOnly?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, adminOnly = false }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    async function checkUser() {
      try {
        const user = await getCurrentUser();
        if (!user) {
          throw new Error('No user found');
        }

        if (isMounted) {
          setIsAuthenticated(true);
          const token = localStorage.getItem('token');
          
          if (!token) {
            throw new Error('No token found');
          }

          try {
            const role = await getUserRole(user.username);
            if (isMounted) {
              setIsAdmin(role === 'admin');
            }
          } catch (error: any) {
            console.error('Error fetching user role:', error);
            if (isMounted) {
              // If token is invalid, redirect to login
              if (error.message === 'Please login again.') {
                setIsAuthenticated(false);
                navigate('/login');
                return;
              }
              setIsAdmin(false);
              setError(error.message);
            }
          }
        }
      } catch (error) {
        console.error('Error checking user:', error);
        if (isMounted) {
          setIsAuthenticated(false);
          setIsAdmin(false);
          setError('Please log in to continue.');
          navigate('/login');
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    checkUser();

    return () => {
      isMounted = false;
    };
  }, [navigate]);

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate('/login');
    }
  }, [loading, isAuthenticated, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!isAuthenticated) {
    return null; // We'll handle redirection in the useEffect above
  }

  if (adminOnly && !isAdmin) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default PrivateRoute;
