import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { bottom: 0; opacity: 0; }
  to { bottom: 20px; opacity: 1; }
`;

const fadeOut = keyframes`
  from { bottom: 20px; opacity: 1; }
  to { bottom: 0; opacity: 0; }
`;

const ToastContainer = styled.div<{ show: boolean }>`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 16px;
  border-radius: 4px;
  z-index: 1000;
  animation: ${props => props.show ? fadeIn : fadeOut} 0.5s;
`;

interface ToastProps {
  message: string;
  show: boolean;
  onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, show, onClose }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  if (!show) return null;

  return <ToastContainer show={show}>{message}</ToastContainer>;
};

export default Toast;