import React, { createContext, useState, useContext, useEffect } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import { getUserRole } from '../services/userService';

interface UserWithRole {
  cognitoUser: any;
  role: string | null | undefined;
}

interface AuthContextType {
  user: UserWithRole | null;
  setUser: React.Dispatch<React.SetStateAction<UserWithRole | null>>;
  refreshUser: (role?: string | null) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserWithRole | null>(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const refreshUser = async (providedRole?: string | null) => {
    try {
      const cognitoUser = await getCurrentUser();
      let role = providedRole;
      if (cognitoUser && role === undefined) {
        try {
          role = await getUserRole(cognitoUser.username);
        } catch (error) {
          console.error('Error fetching user role:', error);
          role = null; // Set to null if there's an error fetching the role
        }
      }
      const userWithRole: UserWithRole = { cognitoUser, role };
      setUser(userWithRole);
      localStorage.setItem('user', JSON.stringify(userWithRole));
    } catch (error) {
      console.error('Error fetching current user:', error);
      setUser(null);
      localStorage.removeItem('user');
    }
  };

  useEffect(() => {
    refreshUser();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, refreshUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};