import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import styled, { keyframes } from 'styled-components';
import logoImage from '../assets/logos/bold_medium_corp_logo.png'; // Adjust the path based on your folder structure
import { signOut } from 'aws-amplify/auth';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';

// Header styles
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 120px 10px; // Increased top padding
  background-color: #000;
  height: 100px; // Increased height for more space

  @media (max-width: 768px) {
    justify-content: center;
    padding: 20px 10px 10px;
  }
`;

// Keyframes for the logo animation
const logoEntrance = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

// Keyframes for the menu animation
const menuEntrance = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
`;

const Logo = styled(Link)`
  display: block;
  width: 375px;
  background-image: url(${logoImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  animation: ${logoEntrance} 1s ease-out forwards;
  min-width: 350px;
  height: 60px; // Reduced height to move logo down
  margin-top: 10px; // Added margin to push logo down further
  cursor: pointer;

  @media (max-width: 768px) {
    width: 200px;
    min-width: unset;
    background-position: center;
  }
`;

// Navigation styles
const Nav = styled.nav`
  height: 100%;
  display: flex;
  align-items: flex-end; // Align items to bottom
  padding-bottom: 10px; // Add some bottom padding

  @media (max-width: 768px) {
    display: none;
  }

  ul {
    list-style: none;
    display: flex;
    gap: 40px;
    align-items: center;
    height: auto; // Allow natural height
  }

  li {
    color: #fff;
    cursor: pointer;
    font-size: 24px;
    transition: opacity 0.3s ease;
    opacity: 0.7;
    animation: ${menuEntrance} 1s ease-out;

    &:hover {
      opacity: 1;
    }
  }

  ul:hover li:not(:hover) {
    opacity: 0.5;
  }
`;

const UserMenu = styled.div`
  position: relative;
  cursor: pointer;
  color: #fff;
  font-size: 24px;
  transition: opacity 0.3s ease;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

const UserMenuDropdown = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  border: 1px solid #333;
  border-radius: 4px;
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 10;
  min-width: 150px;
  text-align: center;
`;

const MenuItem = styled.div`
  padding: 10px 20px;
  color: #fff;
  font-size: 18px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #333;
  }
`;

const MemoizedMenuItem = memo(({ onClick, children }: { onClick: () => void; children: React.ReactNode }) => (
  <MenuItem onClick={onClick}>{children}</MenuItem>
));

const HeaderComponent: React.FC = () => {
  const navigate = useNavigate();
  const { user, refreshUser, setUser } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Use a separate function to call refreshUser
    const refresh = () => {
      refreshUser();
    };

    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array

  const handleLogout = useCallback(async () => {
    try {
      // Sign out from Cognito
      await signOut();

      // Clear all auth-related data from localStorage
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      // Add any other auth-related items you might have in localStorage
      // For example:
      // localStorage.removeItem('lastLoginTime');

      // Clear session storage if you're using it
      sessionStorage.clear();

      // Reset the user in the AuthContext
      setUser(null);

      // Clear any application state that might contain user-specific data
      // If you're using Redux, dispatch an action to clear the store
      // For example:
      // dispatch(clearUserData());

      // If you're using any other state management, clear it here
      // For example, if you have any global state:
      // setGlobalState({});

      // Clear any cookies related to authentication
      // This depends on how you've set cookies, but here's a general approach:
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });

      // Optionally, you can add a small delay before navigation to ensure all async operations are complete
      setTimeout(() => {
        // Navigate to login page
        navigate("/login");
      }, 100);

    } catch (error) {
      console.error("Error signing out: ", error);
      // Optionally, show an error message to the user
      // For example:
      // setErrorMessage("Failed to sign out. Please try again.");
    }
  }, [navigate, setUser]);

  const toggleMenu = useCallback(() => setIsMenuOpen(prev => !prev), []);

  const closeMenu = useCallback(() => setIsMenuOpen(false), []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeMenu]);

  const handleMenuItemClick = useCallback((action: () => void) => {
    action();
    closeMenu();
  }, [closeMenu]);

  const handleNavigateToFiles = useCallback(() => {
    navigate('/files');
  }, [navigate]);

  const handleNavigateToCreateUser = useCallback(() => {
    navigate('/admin/create-user');
  }, [navigate]);

  const isAdmin = user?.role === 'admin';

  return (
    <Header>
      <Logo to="/" />
      <Nav>
        <ul>
          <li>ABOUT</li>
          <li>GAMES</li>
          <li>NEWS</li>
          <UserMenu ref={menuRef}>
            <div onClick={toggleMenu}>
              {user?.cognitoUser?.username?.toUpperCase() || 'USER'}
            </div>
            <UserMenuDropdown isOpen={isMenuOpen}>
              <MemoizedMenuItem onClick={() => handleMenuItemClick(handleNavigateToFiles)}>
                UPLOAD
              </MemoizedMenuItem>
              {isAdmin && (
                <MemoizedMenuItem onClick={() => handleMenuItemClick(handleNavigateToCreateUser)}>
                  CREATE USER
                </MemoizedMenuItem>
              )}
              <MemoizedMenuItem onClick={() => handleMenuItemClick(handleLogout)}>
                LOGOUT
              </MemoizedMenuItem>
            </UserMenuDropdown>
          </UserMenu>
        </ul>
      </Nav>
    </Header>
  );
};

export default memo(HeaderComponent);