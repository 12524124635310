import React from 'react';
import { styled } from 'styled-components';
import { fadeIn } from '../styles/animations';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  animate?: "fadeIn"; // Extend this as needed for other animations
  animationDuration?: number; // Duration in milliseconds
}

const StyledButton = styled.button<ButtonProps>`
  margin-top: 16px;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  
  &:hover {
    background-color: #0056b3;
  }

  animation: ${({ animate, animationDuration }) => 
    animate === 'fadeIn' ? `${fadeIn} ${animationDuration || 500}ms ease-out` : 'none'};
`;

const Button: React.FC<ButtonProps> = ({ children, onClick, type = "button" }) => {
  return (
    <StyledButton type={type} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

export default Button;