import styled from 'styled-components';

export const PageContainer = styled.div`
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const LighterArea = styled.div`
  background-color: #1a1a1a;
  border-radius: 8px;
  padding: 40px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h1`
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 20px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Input = styled.input`
  background-color: #333333;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  padding: 10px;
  font-size: 16px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #555555;
  }
`;

export const Select = styled.select`
  background-color: #333333;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  padding: 10px;
  font-size: 16px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #555555;
  }
`;

export const Button = styled.button`
  background-color: #444444;
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;

  &:hover {
    background-color: #555555;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  padding: 20px 0;
  color: #ffffff;
  opacity: 60%;
  text-align: center;
`;