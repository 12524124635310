import React, { useState } from 'react';
import { resetPassword } from 'aws-amplify/auth';
import InputField from '../../components/InputField';
import Button from '../../components/Button';

const PasswordResetRequest: React.FC = () => {
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const output = await resetPassword({ username });
      if (output.nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        setMessage(`Confirmation code sent to ${output.nextStep.codeDeliveryDetails.destination}`);
      } else if (output.nextStep.resetPasswordStep === 'DONE') {
        setMessage('Password reset successfully.');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setMessage('Error resetting password. Please try again.');
    }
  };

  return (
    <div>
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <InputField
          label="Username"
          type="text"
          name="username"
          id="username"
          required
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Button type="submit">Send Reset Code</Button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default PasswordResetRequest;