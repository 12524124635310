import { createGlobalStyle, keyframes, styled } from "styled-components";
import HeaderComponent from "../../components/Header";

const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  // If you want to ensure that the content fills at least the whole viewport vertically:
  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  * {
  box-sizing: border-box;
}
`;

const PageContainer = styled.div`
  background-color: #000000;
  color: #ffffff;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CenteredContent = styled.div`
  flex-grow: 1; // Take up all available space
  display: flex;
  flex-direction: column;
  justify-content: center; // Center vertically
  align-items: center; // Center horizontally
  text-align: center; // Center text
  width: 100%; // Take the full width
`;

const messageEntrance = keyframes`
  from {
    transform: translateY(60px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ComingSoonMessage = styled.h1`
  color: #fff;
  text-align: center;
  font-size: 2rem;
  animation: ${messageEntrance} 3s ease-out forwards;
  opacity: 0;
  height: 100%;
  margin-top: 2rem;
`;

const Footer = styled.footer`
    width: 100%;
    padding: 20px 0;
    color: #ffffff;
    opacity: 60%;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    `;

const MainPage: React.FC = () => {
    return (
      <>
      <GlobalStyle />
        <PageContainer>
          <HeaderComponent />
          <CenteredContent>
            <ComingSoonMessage>Coming Soon</ComingSoonMessage>
          </CenteredContent>
          {/* Additional content for your main page goes here */}
          <Footer>© 2024 Malorian Software</Footer>
        </PageContainer>
      </>
    );
  };
  
  export default MainPage;