import React, { useEffect, useState } from 'react';
import { getRandomImageUrl } from '../services/imageService';

const RandomImageUrl: React.FC = () => {
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAndRedirect = async () => {
      try {
        const url = await getRandomImageUrl();
        window.location.href = url;
      } catch (error) {
        console.error('Error fetching random image URL:', error);
        setError(error instanceof Error ? error.message : 'An unknown error occurred');
      }
    };

    fetchAndRedirect();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <div>Redirecting to random image...</div>;
};

export default RandomImageUrl;
